.footerContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: black;
  color: white;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}

.quickLinks {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 1rem;
}

.iconsContact {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.iconsMedia {
  display: flex;
  gap: 1rem;
}
.footerP{
  padding: 1rem;
}

/***************************************** Media Qurey ***********************************/


@media only screen and (max-width: 767px) {
  .footerContainer{
    font-size: small;
    text-align: center;
  }
 
}