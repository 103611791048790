.heroContainer {
  position: relative;
  height: 650px;
}

a {
  text-decoration: none;
  color: inherit;
  padding: 1rem;
}

.heroContainerBox {
  width: 100%;
  height: 100%;
}
.heroContainerBoxMob{
  display: none;
}

.titleHomepage{
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.heroImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tagLine {
  position: absolute;
  bottom: 35%;
  right: 3%;
  /* transform: translate(-50%, -50%); */
  font-size: 2rem;
  color: white;
  text-shadow: 4px 4px black;
  font-family: "Courier New", Courier, monospace;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.titleMain {
  font-size: 2.7rem;
}

.imgItemContainer {
  width: 200px;
  height: 200px;
}

.imgItem {
  width: 100%;
  height: 100%;
}

.aboutUsContainer {
  padding-top: 4rem;
  background-color: #0c0225;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
}

.btn {
  background-color: #0c0225;
  width: fit-content;
  border-radius: 6rem;
  font-size: 1.2rem;
  padding: 1rem;
  cursor: pointer;
  text-shadow: none;
}

.btn:hover {
  background-color: green;
}

.cardHome {
  display: flex;
  width: 100%;
  font-size: large;
  gap: 2rem;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
}

.cardInfo {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  padding: 1rem;
}

.imgConatiner {
  width: 500px;
  height: 700px;
  padding: 1rem;
}

.imgCard {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imgConatinerPhil {
  width: 100%;
  height: 400px;
  position: relative;
}

.philImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.textPhilContainer {
  display: flex;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  color: white;
  width: 100%;
}

.textPhil {
  max-width: 800px;
  font-size: x-large;
  text-align: center;
}

.m1 {
  margin-top: 0 !important;
  padding-bottom: 1rem;
}
.t1 {
  font-size: large !important;
}
.servicesHeading{
  text-align: center;
  padding: 1rem;
  font-size: x-large;
}
.missionTag {
  font-size: 2rem;
}

.h1 {
  height: 900px !important;
  padding: 1rem;
}

.p1 {
  padding-bottom: 3rem;
}

/***************************************** Media Qurey ***********************************/


@media only screen and (max-width: 767px) {

  .titleMain{
    font-size: 1rem;
  }
.tagLine{
  font-size: 1rem;
}
.btn{
  font-size: 1rem;
}
.aboutUsContainer{
  padding-top: 1rem;
  font-size: 1.5rem;
}
.imgConatiner{
  display: none;
}
.imgConatinerPhil{
  height: auto;
}

.cardInfo{
  font-size: small;
  padding-top: 0;
}
ul{
  padding-left: 1rem;
}
.cardHome{
  margin-top: 1rem;
}
.t1{
  font-size: small !important;
  padding: 1rem;
}
.textPhil{
  font-size: small;
  padding: 1rem;
}

.missionTag{
  font-size: 1.5rem;
}
.p1{
  padding-bottom: 1rem;
}

.heroContainerBoxMob{
  display: block;
  width: 100%;
  height: 100%;
}
.servicesHeading{
  font-size: medium;
  padding: 0;
  padding-bottom: .5rem;
}
}