.overviewContainer {
  color: white;
  background-color: #0c0225;
  text-align: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.containerHprojects{
  display: flex;
  gap: 2rem;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  flex-wrap: wrap;
}

.projectsHImgContainer{
  width: 300px;
  height: 300px;
  

}

.hImg{
  width: 100%;
  height:100%;
  object-fit: cover;
  border-radius: 100%;
}

.visionTxt {
  width: 800px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.customersSection {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
  padding-bottom: 2rem;
}

.customerLogo {
  width: 100%;
  height: 100%;
}
.logoCustomersContainer {
  object-fit: cover;

  width: 80px;
  height: 80px;
}
.imgCardHighlightedBox{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

 /***************************************** Media Qurey ***********************************/

 @media only screen and (max-width: 767px) {
 .visionTxt{
  width: 100%;
 }

 .customersSection{
  flex-wrap: wrap;
 }
}