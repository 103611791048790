.carouselImg{
    width: 100%;
    height: 600px;
    object-fit: cover;
}

.projectsConatainer{

    background-color: #0c0225;
    color: white;
    text-align: center;
    padding: 1rem;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 3rem;

}
.projectsImgConatainer{
    text-align: center;
    background-color: #0c0225;
color: white;
padding: 1rem;
padding-left: 4rem;
padding-right: 4rem;
}

.carousel{
    margin-top: 1rem !important;
    z-index: 0;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 800px;
    margin-top: 2rem;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: gray;
  }

  /***************************************** Media Qurey ***********************************/

@media only screen and (max-width: 767px) {
   table{
    width: 100%;
    margin-top: 1rem;
   }

   .carouselImg{
    height: 400px;
   }

   .projectsImgConatainer{
    padding-left: 1rem;
    padding-right: 1rem;
   }
   .projectsConatainer{
    padding-top: 1rem;
   }

   .carousel-indicators{
    display: none;
   }

   td{
    font-size: small;
   }
}