.contactUsContainer{
    display: flex;
    flex-direction: column;
    background-color: #0c0225;
    color: white;
    width: 100%;
    align-items: center;
    padding: 1rem;
}
.formContainer{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.directorImgContainer{
    width: 300px;
    height: 300px;
}
.directorImg{
    height: 100%;
    width: 100%;
    border-radius: 50%;

    object-fit: cover;
}

textarea{
    resize: none;
    border-radius: .5rem;
    width: 500px;
    height: 200px;
    background: antiquewhite;
}

.contactUsbtn a{
    padding: 0;
}

.contactUsbtn{
    padding: 1rem;
    border-radius: 6rem;
    background-color: darkgoldenrod;

    width:fit-content;
}

.directorbox{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
}

.contactNum{
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    gap: 1rem;
    width: 100%;
}

.paddMap{
    padding-left: 3rem;
    padding-right: 3rem;
    height: 600px;
}

@media only screen and (max-width: 767px) {
    textarea{
        width: 100%;
    }

    .formContainer{
        flex-direction: column;
        width: 100%;
        padding-top: .5rem;
    }

    .formInput{
        width: 100% !important;
    }
    .paddMap{
        padding: 0rem;
        height: 500px;
    }

}