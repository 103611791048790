.headerContainer {
  display: flex;
  height: 4rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  position: sticky;
  top: 0px;
  z-index: 3;
}

.tab {
  cursor: pointer;
}

.headerOptions {
  display: flex;
  width: 700px;
  justify-content: space-evenly;
  align-items: center;
}

.logoContainer {
  width: 55px;
  height: 55px;
}

.logoImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.headerMobile {
  display: none;
}

.tabUnderlined{
  border-bottom: 2px solid white;
}

/***************************************** Media Qurey ***********************************/

@media only screen and (max-width: 767px) {
  .headerContainer {
    display: none;
  }

  .headerMobile {
    display: flex;
    width: 100%;
    position: sticky;
    background: transparent;
    z-index: 1;
    top: 0px;
    background-color: white;
  }
  .headerMobileOptions {
    display: flex;
    padding: 1rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .menuIconContainer {
    height: 40px;
    width: 40px;
  }
  .menuIconImg {
    width: 100%;
    height: 100%;
  }

  .optionsMenuBox{
    position: absolute;
    z-index: 5;
    top: 87px;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    color: white;
    backdrop-filter: blur(40px);
    height: 100dvh;
    gap: 1rem;
    padding-left: 1rem;

  }
  .pMobile{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
